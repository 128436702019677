import validator from 'validator';
import DOMPurify from 'dompurify';

export function hasRoles(user, roles) {
  return roles.find((role) => user.roles.includes(role));
}

export const validateUrl = (inputUrl) => {
  const url = inputUrl || '';
  return validator.isURL(url) ? DOMPurify.sanitize(url) : '';
}
