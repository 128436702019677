import './ClientUsers.scss';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import _ from 'lodash';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';

let Users = ({ instanceId }) => {
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchClients = () => {
    axios.get('/api/clients', { params: { instanceId } }).then(({ data: { clients } }) => {
      setClients(clients);
    });
  };

  function fetchUsers() {
    axios.get('/api/users', { params: { instanceId } }).then(({ data: { users } }) => {
      setUsers(users.sort((a, b) => a.email && a.email.replace(/[^a-z0-9]/g, '').localeCompare(b.email.replace(/[^a-z0-9]/g, ''))));
    });
  }

  useEffect(() => {
    fetchClients();
    fetchUsers();
  }, [instanceId]);

  async function updateUser(user) {
    await axios.post(
      '/api/users',
      {
        items: user,
      },
      {
        params: { instanceId },
      },
    );
    await fetchUsers();
  }
  async function deleteUser(user) {
    await axios.delete(`/api/users/${user.id}`, {
      params: { instanceId },
    });
    await fetchUsers();
  }

  return (
    <div className="users-container">
      <MaterialTable
        title="Users"
        options={{
          paging: false,
        }}
        columns={[
          { title: 'Email', field: 'email', editable: 'onAdd' },
          {
            title: 'Client',
            field: 'client_id',
            searchable: false,
            removable: true,
            render: (rowData) =>
              _.get(
                clients.find((client) => client.id === rowData.client_id),
                'name',
                null,
              ),
            editComponent: ({ value, onChange, rowData }) => (
              <FormControl>
                <InputLabel>Client</InputLabel>
                <Select value={value || ''} onChange={(e) => onChange(e.target.value || null)}>
                  <MenuItem key="null" value="">
                    -
                  </MenuItem>
                  {clients.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ),
          },
        ]}
        data={users}
        editable={{
          onRowAdd: updateUser,
          onRowUpdate: updateUser,
          onRowDelete: deleteUser,
          deleteTooltip: () => 'Delete the user permanently. This action can not be undone.',
        }}
      />
    </div>
  );
};

export default Users;
