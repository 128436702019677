import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

export default function SelectIcon({ onChange, value, label, instanceId }) {
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    axios.get('/api/icons', { params: { instanceId } }).then(({ data: icons }) => {
      setIcons(icons);
    });
  }, []);

  const select = (
    <Select labelId={value} value={value} onChange={onChange}>
      <MenuItem key="null" value="">
        -
      </MenuItem>
      {icons.map((icon) => (
        <MenuItem key={icon.id} value={icon.id}>
          {icon.name}
        </MenuItem>
      ))}
    </Select>
  );

  return label ? (
    <FormControl variant="outlined">
      <InputLabel id={value}>{label}</InputLabel>
      {select}
    </FormControl>
  ) : (
    select
  );
}

SelectIcon.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
};
