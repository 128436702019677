import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link, useLocation } from 'react-router-dom';

export default function _Tabs() {
  let { pathname } = useLocation();
  const token = localStorage.getItem('accessToken');

  return (
    token && (
      <Paper square>
        <Tabs indicatorColor="primary" textColor="primary" value={'/' + pathname.split('/')[1]} variant="scrollable">
          <Tab label="Clients" value="/clients" to="/clients" component={Link} />
          <Tab label="Expert Contacts" value="/contacts" to="/contacts" component={Link} />
          <Tab label="Crisis Guidelines" value="/crisis-items" to="/crisis-items" component={Link} />
          <Tab label="Icons" value="/icons" to="/icons" component={Link} />
          <Tab label="Users" value="/users" to="/users" component={Link} />
        </Tabs>
      </Paper>
    )
  );
}
