import React, { useEffect, useState } from 'react';
import { ROLES } from '../../constants';
import { hasRoles } from '../../utils';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialTable from 'material-table';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Modal from '../Modal';
import { CLOUD_PROVIDERS } from '../../constants';

export default function Instances({ instanceId, setInstanceId }) {
  const classes = useStyles();
  const token = localStorage.getItem('accessToken');

  const [instances, setInstances] = useState([]);
  const [showInstanceManagement, setShowInstanceManagement] = useState(false);

  useEffect(() => {
    if (!token) return;
    const user = jwtDecode(token);
    if (hasRoles(user, [ROLES.SUPER_ADMIN])) {
      getAllInstances();
    }
  }, [token]);

  function getAllInstances() {
    axios.get('/api/instances').then(({ data: instances }) => setInstances(instances));
  }

  async function addInstance(instance) {
    await axios.post('/api/instances', instance);
    await getAllInstances();
  }

  async function updateInstance(instance) {
    await axios.put('/api/instances/' + instance.id, instance);
    await getAllInstances();
  }

  async function deleteInstance(instance) {
    await axios.delete('/api/instances/' + instance.id);
    await getAllInstances();
  }

  function onInstanceChange(e) {
    const newInstance = e.target.value;
    if (newInstance === 'add') {
      // Show manage instance modal
      setShowInstanceManagement(true);
    } else {
      setInstanceId(newInstance);
    }
  }

  return (
    <>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={instanceId}
        onChange={onInstanceChange}
        classes={{ root: classes.root, icon: classes.icon }}
        disableUnderline
      >
        <MenuItem key="add" value="add">
          Manage
        </MenuItem>
        {instances.map((instance) => (
          <MenuItem key={instance.id} value={instance.id}>
            {instance.name}
          </MenuItem>
        ))}
      </Select>

      <Modal isOpen={showInstanceManagement} onClose={() => setShowInstanceManagement(false)} fullWidth={true} maxWidth="xl">
        <MaterialTable
          style={{
            boxShadow: 'none',
          }}
          title="Manage instances"
          columns={[
            { title: 'Name', field: 'name' },
            {
              title: 'Cloud Provider',
              field: 'cloudProviderType',
              emptyValue: CLOUD_PROVIDERS[0],
              editComponent: ({ value, onChange, rowData }) => (
                <FormControl>
                  <InputLabel>Cloud Provider</InputLabel>
                  <Select value={value} onChange={(e) => onChange(e.target.value)}>
                    {CLOUD_PROVIDERS.map((provider) => (
                      <MenuItem key={provider} value={provider}>
                        {provider}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ),
            },
            { title: 'Provider name', field: 'cloudProviderName' },
            { title: 'SMS sent', field: 'smsSent', editable: 'never' },
            { title: 'Calendar ID', field: 'calendarId' },
            { title: 'Client ID', field: 'cloudProviderClientId' },
            { title: 'Client secret', field: 'cloudProviderClientSecret', searchable: false },
            { title: 'Refresh token', field: 'cloudProviderRefreshToken', searchable: false },
          ]}
          options={{
            paging: false,
          }}
          data={instances}
          editable={{
            onRowAdd: addInstance,
            onRowUpdate: updateInstance,
            onRowDelete: deleteInstance,
          }}
        />
      </Modal>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(5),
    color: 'white',
  },
  icon: {
    color: 'white',
  },
}));
