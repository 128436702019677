import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

export default function Modal({ onClose, isOpen, title, children, ...props }) {
  return (
    <Dialog onClose={onClose} open={isOpen} {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <Box p={2} pb={5}>
        {children}
      </Box>
    </Dialog>
  );
}
