import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Clients from './containers/Clients';
import AddOrEditClient from './containers/AddOrEditClient';
import ExpertContacts from './containers/ExpertContacts';

import Header from './components/Header';
import Tabs from './components/Tabs';
import CrisisItems from './containers/CrisisItems';
import AddOrEditCrisisItem from './containers/AddOrEditCrisisItem';
import Icons from './containers/Icons';
import AddOrEditIcon from './containers/AddOrEditIcon';
import Users from './containers/Users';
import { jwtDecode } from "jwt-decode";
import _ from 'lodash';

import ProtectedRoute from './components/ProtectedRoute';

import Auth from './containers/Auth';
import SignInWebhook from './containers/SignInWebhook';

function App() {
  const [instanceId, setInstanceId] = useState(() => {
    const token = localStorage.getItem('accessToken');
    if (!token) return null;
    const user = jwtDecode(token);
    return _.get(user, 'instanceId');
  });

  return (
    <Router>
      <Header instanceId={instanceId} setInstanceId={setInstanceId} />
      <Tabs />
      <CssBaseline />
      <Box height="80vh" m={2}>
        <Switch>
          <Route exact path="/auth">
            <Auth />
          </Route>
          <Route exact path="/webhook/sign-in">
            <SignInWebhook setInstanceId={setInstanceId} />
          </Route>
          <ProtectedRoute exact path="/clients">
            <Clients instanceId={instanceId} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/clients/add">
            <AddOrEditClient instanceId={instanceId} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/clients/edit/:id">
            <AddOrEditClient instanceId={instanceId} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/contacts">
            <ExpertContacts instanceId={instanceId} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/crisis-items">
            <CrisisItems instanceId={instanceId} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/crisis-items/add">
            <AddOrEditCrisisItem instanceId={instanceId} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/crisis-items/edit/:id">
            <AddOrEditCrisisItem instanceId={instanceId} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/icons">
            <Icons instanceId={instanceId} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/icons/add">
            <AddOrEditIcon instanceId={instanceId} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/icons/edit/:id">
            <AddOrEditIcon instanceId={instanceId} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/users">
            <Users instanceId={instanceId} />
          </ProtectedRoute>
          <Redirect exact from="/" to="/clients" />
        </Switch>
      </Box>
    </Router>
  );
}

export default App;
