import React, { useEffect, useState } from 'react';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { validateUrl } from '../../utils';

import Modal from '../../components/Modal';

let Clients = ({ instanceId }) => {
  const history = useHistory();
  const classes = useStyles();
  const [clients, setClients] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    axios.get('/api/clients', { params: { instanceId } }).then(({ data: { clients } }) => setClients(clients));
  }, [instanceId]);

  const onDeleteClient = (id) => {
    axios
      .delete('/api/clients', {
        params: { id, instanceId },
      })
      .then(() => {
        setClients((clients) => clients.filter((client) => client.id !== id));
      })
      .catch((error) => {
        if (_.get(error, 'response.status', false) === 409) {
          setErrorMessage(error.response.data.message);
        }
      });
  };

  const onEditClient = (id) => {
    history.push(`/clients/edit/${id}`);
  };

  const onAddClient = () => {
    history.push('/clients/add');
  };

  return (
    <div>
      <Modal isOpen={!!errorMessage} onClose={() => setErrorMessage(false)} title="Users in Client">
        <Typography>{errorMessage}</Typography>
      </Modal>
      {clients
        .slice(0)
        .sort((a, b) => a.name - b.name)
        .map((client) => (
          <List key={client.id}>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={validateUrl(client.iconUrl)}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={client.name} />
              <ListItemSecondaryAction>
                <IconButton aria-label="edit" onClick={() => onEditClient(client.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => onDeleteClient(client.id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </List>
        ))}
      <Tooltip title="Add Client" aria-label="add">
        <Fab color="primary" className={classes.fab} onClick={onAddClient}>
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  );
};

export default Clients;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
