import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import { validateUrl } from '../../utils';

import './Contacts.scss';

import SelectIcon from '../../components/SelectIcon';
import Modal from '../../components/Modal';

export default function ExpertContacts({ instanceId }) {
  const [showModal, setShowModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [columns, setColumns] = useState([]);
  const [defaultColumns, setDefaultColumns] = useState([
    {
      title: 'Picture',
      field: 'icon',
      render: (rowData) => (rowData.icon === null ? null : <Avatar src={validateUrl(rowData.iconUrl)} />),
      editComponent: ({ value, onChange, rowData }) => {
        return <SelectIcon value={value == null ? '' : value.toString()} onChange={(e) => onChange(e.target.value === '' ? null : e.target.value)} instanceId={instanceId} />;
      },
      filtering: false,
      width: 30,
    },
    {
      title: 'Display',
      field: 'display',
      render: (rowData) => {
        return <Checkbox checked={rowData.display} disabled />;
      },
      editComponent: ({ value, onChange }) => {
        return <Checkbox checked={value} onChange={(e) => onChange(e.target.checked ? 1 : 0)} />;
      },
      filtering: false,
      width: 30,
    },
    { title: 'Name', field: 'name' },
    {
      title: 'Phone',
      field: 'phone',
    },
    { title: 'Email', field: 'email' },
    { title: 'Title', field: 'title' },
  ]);

  useEffect(() => {
    getContacts();
    getCrisisItems();
  }, [instanceId]);

  async function getCrisisItems() {
    axios.get(`/api/crisisitems`, { params: { instanceId } }).then(({ data: { crisisItems } }) => {
      setColumns(
        crisisItems
          .reduce((subAndCrisis, crisis) => {
            if (crisis.subItems.length > 0)
              return subAndCrisis.concat(
                crisis.subItems.map((subItem) => ({
                  ...subItem,
                  title: crisis.title + '-' + subItem.title,
                  isSubCrisis: true,
                })),
              );
            return subAndCrisis.concat({
              ...crisis,
              isCrisis: true,
            });
          }, [])
          .map((crisisItem) => ({
            title: crisisItem.title,
            field: crisisItem.id.toString(),
            isCrisis: crisisItem.isCrisis,
            isSubCrisis: crisisItem.isSubCrisis,
            filtering: false,
            sorting: false,
            searchable: false,
            render: (rowData) => {
              const isSelected = !!rowData[crisisItem.isCrisis ? 'crisisIds' : 'subCrisisIds'].find((_id) => _id === crisisItem.id);
              return <Checkbox checked={isSelected} disabled />;
            },
            headerStyle: {
              maxWidth: 60,
              transform: 'rotate(45deg) translate(50px, -45px)',
            },
            editComponent: (e) => {
              const {
                rowData,
                onRowDataChange,
                columnDef: { field: id, isCrisis },
              } = e;
              const type = isCrisis ? 'crisisIds' : 'subCrisisIds';
              const isChecked = !!(rowData[type] || []).find((cId) => cId === Number(id));
              function onChange(e) {
                const checked = e.target.checked;
                if (!rowData[type]) {
                  rowData[type] = [Number(id)];
                } else if (checked) {
                  rowData[type] = rowData[type].concat(Number(id));
                } else {
                  rowData[type] = rowData[type].filter((_id) => _id !== Number(id));
                }

                onRowDataChange(rowData);
              }
              return <Checkbox checked={isChecked} onChange={onChange} />;
            },
          })),
      );
    });
  }

  async function getContacts() {
    const {
      data: { contacts },
    } = await axios.get(`/api/contacts`, { params: { instanceId, all: true } });
    setContacts(contacts);
  }

  async function deleteContact(id) {
    //Optimistic update
    setContacts(contacts.filter((c) => c.id !== id));
    await axios.delete('/api/contacts', {
      data: { id },
      params: { instanceId },
    });
  }

  async function updateContact(contact) {
    if (hasDuplicateEmail(contact)) {
      return setShowModal(true);
    }
    await axios.patch(`/api/contacts/${contact.id}`, contact, { params: { instanceId } });
    await getContacts();
  }

  async function saveContact(contact) {
    if (hasDuplicateEmail(contact)) {
      return setShowModal(true);
    }
    await axios.post(`/api/contacts`, contact, { params: { instanceId } });
    await getContacts();
  }

  function hasDuplicateEmail(newContact) {
    let allEmails = [];
    if (newContact.id) {
      allEmails = contacts.map((contact) => {
        return contact.id === newContact.id ? newContact.email : contact.email;
      });
    } else {
      allEmails = contacts.map((c) => c.email).concat(newContact.email);
    }

    return _.uniq(allEmails).length !== allEmails.length;
  }

  return (
    <div className="contacts">
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Unique email addresses">
        <Typography>Contacts can't have the same email addresses</Typography>
      </Modal>
      <MaterialTable
        title="Expert Contacts"
        columns={defaultColumns.concat(columns)}
        data={contacts}
        options={{
          paging: false,
          headerStyle: {
            whiteSpace: 'nowrap',
            height: 200,
            verticalAlign: 'bottom',
            width: 120,
          },
          cellStyle: {
            whiteSpace: 'nowrap',
            paddingLeft: 5,
            paddingRight: 5,
          },
        }}
        editable={{
          onRowAdd: (contact) => saveContact(contact),
          onRowUpdate: (contact) => updateContact(contact),
          onRowDelete: (contact) => deleteContact(contact.id),
        }}
      />
    </div>
  );
}
