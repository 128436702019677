import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { access_type, client_id, identity_endpoint, redirect_uri, response_type, scope } from '../../constants';

let Auth = (props) => {
  const classes = useStyles();

  const url = `${identity_endpoint}?scope=${scope}&response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&access_type=${access_type}`;

  return (
    <Box width="100%" height="50%" display="flex" justifyContent="center" alignItems="center" textAlign="center">
      <Paper height="100%" className={classes.paper}>
        <Typography variant="h5" component="h1" gutterBottom>
          Authenticate
        </Typography>
        <Button component="a" href={url} variant="outlined" color="secondary">
          Authenticate with PwC
        </Button>
      </Paper>
    </Box>
  );
};

export default Auth;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));
