import './index.css';
// import 'typeface-roboto';

import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";

import NetworkError from './components/NetworkError';
import App from './App';
import { API_URL } from './constants';

const theme = createTheme({
  typography: {
    fontFamily: 'Helvetica Neue',
  },
  palette: {
    primary: {
      main: '#d04a02',
    },
    secondary: {
      main: '#299d8f',
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        margin: 10,
      },
    },
    MuiSelect: {
      select: {
        minWidth: 120,
      },
    },
    MuiPaper: {
      elevation0: {
        marginTop: 10,
        marginBottom: 20,
        padding: 10,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Index() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState(null);
  const [hasDefaultConfig, setHasDefaultConfig] = useState(false);

  const handleTokenRefresh = async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const idToken = localStorage.getItem('idToken');

    if (!accessToken) return;
    const { exp } = jwtDecode(accessToken);

    const isExpired = new Date().getTime() / 1000 > Number(exp) - 5;
    const isRefreshUrl = (url) => new RegExp(/\/refresh-token/).test(url);

    if (isExpired && !isRefreshUrl(config.url)) {
      await axios
        .post(`${API_URL}/auth/admin/refresh-token`, {
          refreshToken,
          idToken,
        })
        .then((res) => {
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('refreshToken', res.data.refreshToken);
          localStorage.setItem('idToken', res.data.idToken);
        });
    }
  };

  useEffect(() => {
    axios.defaults.baseURL = API_URL;

    axios.interceptors.request.use(
      async function (config) {
        setIsLoading(true);
        await handleTokenRefresh(config);
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        };
      },
      function (error) {
        setIsLoading(false);
        setNetworkError(error);
        console.log('NetworkError', error);
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      function (response) {
        setIsLoading(false);
        return response;
      },
      function (error) {
        setIsLoading(false);
        console.log('NetworkError', error);
        if (error.response && 401 === error.response.status) {
          localStorage.clear();
          window.location.reload();
        } else {
          setNetworkError(error);
          return Promise.reject(error);
        }
      },
    );
    setHasDefaultConfig(true);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NetworkError error={networkError} setError={setNetworkError} />
      {hasDefaultConfig && <App />}
    </ThemeProvider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Index />);
