import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { validateUrl } from '../../utils';

export default function Icons({ instanceId }) {
  const history = useHistory();
  const classes = useStyles();
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    axios.get('/api/icons', { params: { instanceId } }).then(({ data: icons }) => setIcons(icons));
  }, [instanceId]);

  function editIcon(iconId) {
    history.push('/icons/edit/' + iconId);
  }

  function deleteIcon(iconId) {
    setIcons(icons.filter((i) => i.id !== iconId));
    axios.delete(`/api/icons/${iconId}`, { params: { instanceId } });
  }

  function addIcon() {
    history.push('/icons/add');
  }

  return (
    <div>
      {icons.map((icon) => (
        <List key={icon.id}>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={validateUrl(icon.url)}></Avatar>
            </ListItemAvatar>
            <ListItemText primary={icon.name} />
            <ListItemSecondaryAction>
              <IconButton aria-label="edit" onClick={() => editIcon(icon.id)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => deleteIcon(icon.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </List>
      ))}
      <Tooltip title="Add Icon" aria-label="add">
        <Fab color="primary" className={classes.fab} onClick={addIcon}>
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
