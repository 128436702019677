import { useEffect } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

export default function SignInWebhook({ setInstanceId }) {
  const location = useLocation();
  let history = useHistory();

  useEffect(() => {
    if (location && location.search) {
      const { code } = queryString.parse(location.search);

      axios({
        method: 'post',
        url: '/auth/exchange',
        data: { code },
      }).then((result) => {
        const { accessToken, refreshToken, idToken } = _.get(result, 'data', {});
        const user = jwtDecode(accessToken);
        setInstanceId(_.get(user, 'instanceId'));
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('idToken', idToken);
        history.push(`/clients`);
      });
    }
  }, [location]);

  return null;
}
