import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

const sessionTimeoutInMs = 15 * 60 * 1000;
const updateExpirationTime = () => localStorage.setItem('sessionExpirationTime', new Date().getTime() + sessionTimeoutInMs);

const checkInactivity = async (history) => {
  const expirationTime = localStorage.getItem('sessionExpirationTime');

  if (new Date().getTime() > expirationTime) {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const idToken = localStorage.getItem('idToken');
    try {
      await axios.post(`/auth/invalidate-token`, { accessToken, refreshToken, idToken });
    } catch (error) {
      console.log(error);
    }
    localStorage.clear();
    history.push('/');
  }
};
export default function ProtectedRoute({ children, ...routeProps }) {
  const history = useHistory();

  useEffect(() => {
    updateExpirationTime();
    window.addEventListener('click', updateExpirationTime);
    window.addEventListener('keypress', updateExpirationTime);
    window.addEventListener('mousemove', updateExpirationTime);
    window.addEventListener('scroll', updateExpirationTime);

    return () => {
      window.removeEventListener('click', updateExpirationTime);
      window.removeEventListener('keypress', updateExpirationTime);
      window.removeEventListener('mousemove', updateExpirationTime);
      window.removeEventListener('scroll', updateExpirationTime);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      checkInactivity(history);
    }, sessionTimeoutInMs);
    return () => clearInterval(interval);
  }, []);

  const token = localStorage.getItem('accessToken');

  if (!token) {
    window.location.href = '/auth';
    return;
  }

  return <Route {...routeProps}>{children}</Route>;
}

ProtectedRoute.propTypes = {
  component: PropTypes.element,
};
