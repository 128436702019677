import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { validateUrl } from '../../utils';

let CrisisItems = ({ instanceId }) => {
  const classes = useStyles();
  const history = useHistory();
  const [crisisItems, setCrisisItems] = useState([]);

  useEffect(() => {
    axios.get('/api/crisisitems', { params: { instanceId } }).then((result) => {
      setCrisisItems(result.data.crisisItems);
    });
  }, [instanceId]);

  function deleteCrisisItem(id) {
    // Optimistic update
    setCrisisItems(crisisItems.filter((c) => c.id !== id));

    axios.delete('/api/crisisitems', {
      data: { id },
      params: {
        instanceId,
      },
    });
  }

  function editCrisis(crisisId) {
    history.push(`/crisis-items/edit/${crisisId}`);
  }

  function addCrisis() {
    history.push('/crisis-items/add');
  }

  return (
    <div>
      {crisisItems
        .slice(0)
        .sort((a, b) => a.title - b.title)
        .map((crisis, index) => (
          <List key={crisis.id}>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={validateUrl(crisis.iconUrl)}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={crisis.title} secondary={crisis.phone} />
              <ListItemSecondaryAction>
                <IconButton aria-label="edit" onClick={() => editCrisis(crisis.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => deleteCrisisItem(crisis.id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </List>
        ))}
      <Tooltip title="Add Crisis" aria-label="add">
        <Fab color="primary" className={classes.fab} onClick={addCrisis}>
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  );
};

export default CrisisItems;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
